<template>
  <div class="login">
    <Header />
    <b-container class="login-container">
      <b-row class="justify-content-center w-100 m-0">
        <div>
          <b-card-group deck class="w-100 m-0">
            <b-card class="m-0">
              <div class="row">
                <div class="col-lg-6 col-md-6 my-auto">
                  <div class="login-form">
                    <h1>Masuk ke akun iTradeFund Anda</h1>
                    <template>
                      <div>
                        <form action="" method="POST" @submit.prevent="store">
                          <h3 class="login-label">User ID iTrade</h3>
                          <vs-input
                            id="email"
                            type="text"
                            class="mb-2 mr-sm-2 mb-sm-0"
                            v-model="client_id"
                            @keyup="handleInputOnKeyup"
                            required
                          />
                          <!-- <div class="my-2" v-if="emailNotFoundShow">
                            <p>User ID belum terdaftar</p>
                          </div> -->
                          <b-row>
                            <b-col>
                              <h3 class="login-label">Password</h3>
                            </b-col>
                            <b-col class="text-right login-label mb-2">
                              <b-link
                                class="forgot-password-link"
                                to="/forgot-password"
                                >Lupa password?</b-link
                              >
                            </b-col>
                          </b-row>
                          <div class="row">
                            <div class="col-9">
                              <vs-input
                                id="password"
                                type="password"
                                v-show="!showPass"
                                class="mb-2 mr-sm-2 mb-sm-0"
                                v-model="password"
                                required
                              />
                              <vs-input
                                id="password"
                                type="text"
                                v-show="showPass"
                                class="mb-2 mr-sm-2 mb-sm-0"
                                v-model="password"
                                required
                              />
                            </div>
                            <div class="col-3">
                              <b-button
                                @click="showPass = !showPass"
                                block
                                class="btn-show-password py-2"
                              >
                                <div v-show="!showPass">
                                  <b-icon icon="eye"></b-icon>
                                </div>
                                <div v-show="showPass">
                                  <b-icon icon="eye-slash"></b-icon>
                                </div>
                              </b-button>
                            </div>
                          </div>

                          <div class="my-2" v-if="authenticationFailed">
                            <p class="text-danger">
                              Password yang Anda masukkan salah. <br />
                              Coba lagi atau klik “Lupa Password?”
                            </p>
                          </div>
                          <div class="my-2" v-if="emailNotVerifiedShow">
                            <p class="text-danger">
                              Email belum terverifikasi <br />
                              Silahkan cek email, dan lakukan verifikasi dengan
                              memasukkan OTP
                            </p>
                          </div>
                          <!-- <template>
                                    <div class="remember-me-checkbox py-3">
                                      <vs-checkbox
                                      >Ingat saya </vs-checkbox>                                      
                                    </div>
                                </template> -->
                          <div class="row justify-content-center">
                            <div class="col-lg-12 col-md-6 pt-4">
                              <b-button
                                block
                                :disabled="processing"
                                type="submit"
                                @click="store()"
                                class="btn-tertiary py-2"
                              >
                                <div v-if="processing">
                                  <div
                                    class="spinner-border spinner-border-sm"
                                  ></div>
                                  Loading
                                </div>
                                <div v-else>Login</div>
                              </b-button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </template>
                    <b-row class="justify-content-center dont-have-account-row">
                      <div class="dont-have-account">
                        <span
                          >Belum punya akun Reksa Dana?
                          <b-link
                            class="sign-up-link registerLinkCustom"
                            to="/register"
                            >Register</b-link
                          >
                        </span>
                      </div>
                    </b-row>
                    <b-row class="justify-content-end text-center licensed">
                      <div class="col-lg-12 col-md-12">
                        <div class="license-text mt-n3">
                          <span
                            >PT CGS International Sekuritas Indonesia <br />
                            Berizin dan Diawasi oleh Otoritas Jasa Keuangan
                          </span>
                        </div>
                        <div class="version-text text-center">
                          <small class="text-muted"
                            >Version {{ appVersion }}</small
                          >
                        </div>
                      </div>
                      <!-- <div class="col-lg-6 col-md-6">
                        <div class="ojk-logo text-center text-lg-left">
                          <img
                            class="ojk-logo-image"
                            src="@/assets/img/login/ojk-logo.webp"
                            alt=""
                          />
                        </div>
                      </div> -->
                    </b-row>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="login-img">
                    <img
                      src="@/assets/img/login/Frame 1089442.png"
                      alt="login-img"
                      width="102%"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </b-row>
    </b-container>
    <Footer4 />
    <b-modal
      id="modal-failed-register"
      v-model="clientIdNotFound"
      size="md"
      class="modal-failed-register"
      centered
      hide-footer
    >
      <div class="d-block text-center">
        <img
          src="@/assets/img/ekyc/data-not-correct.svg"
          alt="data-not-correct"
        />
        <h1 class="header-modal p-4 my-2 text-dark">
          User ID CGS iTrade Fund itrade tidak ditemukan
        </h1>
        <p class="text-black">Silahkan lakukan registrasi terlebih dahulu</p>
        <b-link
          class="login-link my-2"
          href="https://register.CGS iTrade Fund.co.id/"
          >Silahkan Register disini</b-link
        >
        <!-- <p>{{errorMessage}}</p> -->
      </div>
    </b-modal>
    <b-modal
      id="modal-failed-register"
      v-model="clientIdNotFoundInMutual"
      size="md"
      class="modal-failed-register"
      centered
      hide-footer
    >
      <div class="d-block text-center">
        <img
          src="@/assets/img/ekyc/data-not-correct.svg"
          alt="data-not-correct"
        />
        <h1 class="header-modal p-4 my-2 text-dark">
          Anda belum terdaftar pada Itrade Fund
        </h1>
        <b-link class="login-link my-2 text-primary" to="/register"
          >Silahkan Register disini</b-link
        >
        <!-- <p>{{errorMessage}}</p> -->
      </div>
    </b-modal>
  </div>
</template>

<script>
import Header from "@/components/partials/header/Header.vue";
import Footer4 from "@/components/partials/footer/Footer4.vue";
import { mapActions, mapGetters } from "vuex";
import { encryptData } from "@/utils";
import instance from "@/utils/api";
import { version } from "@/../package.json";

export default {
  name: "Home",
  metaInfo: {
    title: "Login | CGS iTrade Fund",
  },
  components: {
    Header,
    Footer4,
  },
  data() {
    return {
      email: "",
      password: "",
      client_id: "",
      s21ClientEmail: "",
      s21ClientData: "",
      s21IddleMoney: "",
      s2321IddleMoney: "",
      authenticationFailed: false,
      emailNotVerifiedShow: false,
      processing: false,
      showPass: false,
      clientIdNotFound: false,
      clientIdNotFoundInMutual: false,
      appVersion: version,
    };
  },
  computed: {
    ...mapGetters({
      app_url: "app_url",
    }),
  },
  methods: {
    ...mapActions({
      login: "auth/login",
      iddleMoneyFromLogin: "idlemoney/changeIsFromLogin",
    }),

    handleInputOnKeyup() {
      this.client_id = this.client_id.toUpperCase();
    },
    async store() {
      if (!this.processing) {
        this.processing = true;
        try {
          try {
            let response = await instance({
              method: "GET",
              url: `${this.app_url}s21client?clientId=${encryptData(
                this.client_id
              )}`,
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            });
            localStorage.setItem("client_email", response.data.data.Email);
            localStorage.setItem("client_password", this.password);
            localStorage.setItem("client_id", this.client_id);
            this.s21ClientEmail = response.data.data.Email;
            this.s21ClientData = response.data.data.ClientID;
            this.s21IddleMoney = response.data.data.is_idle_money;
          } catch (e) {
            console.log(e);
            this.processing = false;
            this.authenticationFailed = false;
            this.clientIdNotFound = true;
            window.setTimeout(function () {
              window.location.href = "https://register.cgsi.co.id";
            }, 3000);
          }

          if (this.processing) {
            this.processing = true;
            const payload = {
              client_id: this.client_id,
              password: this.password,
            };

            console.log(payload);

            var tmpResponseLogin = await this.login({
              encryptedData: encryptData(JSON.stringify(payload)),
            });

            this.s2321IddleMoney = tmpResponseLogin?.individual?.is_idle_money;
            this.processing = false;

            localStorage.setItem("checkUserId", this.client_id);
            localStorage.setItem("s21IdleMoneyStatus", this.s21IddleMoney);
            localStorage.setItem("s2321IdleMoneyStatus", this.s2321IddleMoney);

            this.iddleMoneyFromLogin(true);

            if (
              this.s21IddleMoney == 1 &&
              this.s2321IddleMoney == 0 &&
              !tmpResponseLogin?.individual?.is_fbonds
            ) {
              // user eligible and not yet participated
              this.$router.push({ path: "/idle-money" });
            } else {
              window.location = "/portfolio";
            }
          }
        } catch (e) {
          console.log(e);
          if (e?.response?.data?.meta?.message == "Authentication Failed") {
            this.processing = false;
            this.clientIdNotFound = false;
            this.authenticationFailed = e.response.data.meta.message;
          } else if (
            e?.response?.data?.meta?.message == "User id is not verified"
          ) {
            this.processing = false;
            this.clientIdNotFound = false;
            this.authenticationFailed = false;
            this.emailNotVerifiedShow = e.response.data.meta.message;
            window.setTimeout(function () {
              window.location.href = "/verification";
            }, 2000);
          } else if (e?.response?.data?.meta?.message == "User id not found") {
            this.processing = false;
            this.clientIdNotFound = false;
            this.authenticationFailed = false;
            this.emailNotVerifiedShow = false;
            this.clientIdNotFoundInMutual = true;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  background: #f3f4f6;
}

.login-container {
  padding-top: 140px;
  padding-bottom: 80px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #111827;
}

h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #4b5563;
}

span {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #4f566b;
}

p {
  font-family: $inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.vs-con-input-label {
  width: 100%;
}

.con-vs-checkbox {
  justify-content: start;
  margin: 0;
}

.login-label {
  padding-top: 16px;
}

.login-form {
  padding: 48px;
}

.login-img {
  margin: 0px;
  padding: 0px;
  box-shadow: none;
}

.forgot-password-link {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #6b7280;
  text-decoration: none;
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.remember-me {
  padding-top: 16px;
  padding-bottom: 16px;
}

.custom-control-label {
  font-family: $inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #3c4257;
}

.custom-control-label:before {
  background-color: #e60012 !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #e60012 !important;
}

.card-body {
  box-shadow: 0px 10px 15px rgba(107, 114, 128, 0.1),
    0px 4px 6px rgba(107, 114, 128, 0.05);
  border-radius: 5px;
  padding: 0px;
}

.form-control:focus {
  outline: none !important;
  border: 1px solid !important;
  box-shadow: none !important;
}

.dont-have-account {
  padding-top: 28px;
}

.sign-up-link {
  font-family: $inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #e60012;
  text-decoration: none;
}

.licensed {
  padding-top: 48px;
}

.license-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #1f2937;
}

.version-text {
  font-style: normal;
  font-weight: normal;
  color: #1f2937;
  font-size: 13px;
}

.ojk-logo-image {
  width: 120px;
}

.modal-login {
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    color: black;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    text-align: center;
    color: #4b5563;
  }
}

@media only screen and (max-width: 768px) {
  .login-container {
    padding-bottom: 60px;
  }

  .card-body {
    width: 100%;
  }

  .login-img {
    display: none;
  }

  .forgot-password-link {
    font-size: 10px;
  }

  .dont-have-account-row {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .licensed {
    text-align: center;
  }

  .ojk-logo-image {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1366px) {
  .login-container {
    padding-bottom: 300px;
  }
}

.registerLinkCustom {
  font-size: 1.1em;
}
</style>
